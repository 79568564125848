import { useMutation } from 'react-query';
import { setTeamAdditionalQuota } from '../services/api/SetTeamAdditionalQuota';

type Props = {
  additionalQuota: number;
  idFeature: number;
  idTeam: number;
  recurringAdditionalQuota?: number;
};

const useSetTeamAdditionalQuota = () => {
  const setTeamFeatureUsageQuery = useMutation(setTeamAdditionalQuota);

  const onSubmit = ({
    additionalQuota,
    idFeature,
    idTeam,
    recurringAdditionalQuota,
  }: Props) => {
    return setTeamFeatureUsageQuery.mutateAsync({
      additional_quota: additionalQuota,
      id_feature: idFeature,
      id_team: idTeam,
      recurring_additional_quota: recurringAdditionalQuota,
    });
  };

  return {
    onSubmit,
    isLoading: setTeamFeatureUsageQuery.isLoading,
  };
};

export default useSetTeamAdditionalQuota;
